<template>
  <div class="header">
    <img
      v-if="userinfo && userinfo.local == 1"
      @click.stop="toHome"
      src="../assets/icon/logo.png"
      class="logo click"
    />
    <img
      v-else-if="userinfo && userinfo.local == 2"
      @click.stop="toHome"
      src="../assets/icon/logo2.png"
      class="logo click"
    />
    <img v-else @click.stop="toHome" src="../assets/icon/logo2.png" class="logo click" />
    <div
      class="newMessage-box click"
      @click.stop="toMyMessagePage(newMessage.id)"
      v-if="newMessage"
    >
      <img src="../assets/icon/message.png" />
      <p v-text="$t('two.message')">留言：</p>
      <span>您在{{newMessage.create_time}}的留言平台回復了,點擊查看具體內容</span>
    </div>
    <div class="header-content-box">
      <p v-if="!uuid" class="header-btn-box">
        <span class="click" @click.stop="showLoginMask = true" v-text="$t('header.login')">登录</span> /
        <span @click.stop="showRegMask = true" class="click" v-text="$t('header.register')">注册</span>
      </p>
      <div class="message-button click" @click.stop="fnToMessagePage" v-text="$t('two.message')">留言</div>
      <!-- <p v-if="userinfo">
        <span class="user-name click" @click.stop="fnToMemberPage">{{userinfo.user_name}}</span>
      </p>-->
      <img src="../assets/icon/member.png" class="ic-member click" @click.stop="fnToMemberPage" />
      <div class="cart-box click" @click.stop="toCartPage">
        <img src="../assets/icon/ic-cart.png" class="ic-cart" />
        <p v-text="$t('header.cart')"></p>
        <div class="number-box">{{cartNumber}}</div>
      </div>
      <div class="search-box">
        <div class="search-box-content">
          <form action="javascript:;" @submit="fnSearchSubmit">
            <div class="input-box">
              <img src="../assets/icon/ic-search1.png" class="ic-search" />
              <el-input v-model="searchVal" :placeholder="$t('header.placeholder')" maxlength="30"></el-input>
            </div>
          </form>
          <div class="search-btn click" @click.stop="fnSearchSubmit" v-text="$t('header.search')"></div>
        </div>
        <div class="keywords">
          <p
            class="click"
            v-for="(item,index) in keywordList"
            :key="index"
            @click.stop="searchVal=item;fnSearchSubmit();"
          >
            <span v-if="index < 5">{{item}}</span>
          </p>
        </div>
      </div>
    </div>
    <!-- 登录蒙层 -->
    <v-login
      v-if="showLoginMask"
      @toRegBox="handleLoginToRegBox"
      @toPasswordBox="handleLoginToPwdBox"
      @loginTourist="handleLoginTourist"
      @fnLoginSuccess="fnLoginSuccess"
      @close="fnCloseMask('showLoginMask')"
    ></v-login>
    <!-- 注册蒙层 -->
    <v-register
      v-if="showRegMask"
      @toLoginBox="handleRegToLoginBox"
      @fnNext="handleRegNext"
      @close="fnCloseMask('showRegMask')"
    ></v-register>
    <!-- 找回密码蒙层 -->
    <v-password
      v-if="showPwdMask"
      @toLoginBox="handlePwdToLoginBox"
      @setPasswordSuccess="setPasswordSuccess"
      @close="fnCloseMask('showPwdMask')"
    ></v-password>
    <!-- 绑定邮箱蒙层 -->
    <v-bind-email
      v-if="showEmailMask"
      @fnSkip="handleBindEmailSkip"
      @close="fnCloseMask('showEmailMask')"
    ></v-bind-email>
    <!-- 微信登录绑定手机号 -->
    <v-wechat-bind-mobile
      :wxData="wxData"
      v-if="wxData"
      @fnLoginSuccess="fnLoginSuccess"
      @close="fnCloseMask('wxData')"
    ></v-wechat-bind-mobile>
  </div>
</template>
<script>
import WechatBindMobile from '../components/wechatBindMobile.vue'
import Login from '../components/login.vue'
import Register from '../components/register.vue'
import Password from '../components/password.vue'
import BindEmail from '../components/bingEmail.vue'
import { MessageBox } from 'element-ui'

export default {
  name: 'Header',
  data() {
    return {
      newMessage: null, //新消息
      keywordList: [],
      showLoginMask: !1,
      showRegMask: !1,
      showPwdMask: !1,
      showEmailMask: !1,
      searchVal: null,
      wxData: null,
      uuid: null,
      userinfo: null,
      cartNumber: 0,
    }
  },
  created() {
    var self = this

    // 微信登录回调参数
    var wxLoginCode = this.common.fnRequestUrlParam('code')
    if (wxLoginCode) this.httpWxLogin(wxLoginCode)

    // 搜索框参数
    var searchVal = this.common.fnRequestUrlParam('searchVal')
    if (searchVal) {
      searchVal = decodeURIComponent(searchVal)
      if (searchVal) this.searchVal = searchVal
    }

    // 用户是否登录
    this.uuid = localStorage.getItem('Wx1e2PtoP')
    if (this.uuid) {
      this.getUserInfo() // 请求用户信息
      this.httpGetCartNumber() //请求购物车数量
      this.httpMessage()
    }

    // 刷新购物车总数
    eventBus.$on('RefreshCartNumber', function () {
      // console.log('RefreshCartNumber...');
      self.httpGetCartNumber()
    })

    // 用户登录拦截
    eventBus.$on('userLoginIntercept', function () {
      if (self.$router.history.current.name != 'home') {
        self.$router.replace('/')
      }
      self.showLoginMask = true
    })

    eventBus.$on('updateUserInfo', function (data) {
      self.getUserInfo()
    })

    // 修改登录密码
    eventBus.$on('updateLoginPassword', function () {
      localStorage.removeItem('Wx1e2PtoP')
      sessionStorage.removeItem('userinfo')
      self.$router.replace('/')
      self.uuid = null
      self.userinfo = null
      self.showLoginMask = true
    })

    this.searchKeywordList()
  },
  methods: {
    toMyMessagePage: function (id) {
      this.$router.push({ path: '/user/messageDetail', query: { id: id } })
    },
    //获取最新留言消息
    httpMessage(code) {
      var self = this
      self.common
        .httpPost('/api/user/newMessageBoard', {}, false)
        .then((ret) => {
          if (ret.code == 1) {
            self.newMessage = ret.data
          }
        })
    },
    /**搜索关键字列表*/
    searchKeywordList: function () {
      var self = this
      var local = localStorage.getItem('local')
      self.common
        .httpPost(
          '/api/ajax/searchKeywordList',
          { local: local ? local : this.common.default_local },
          false
        )
        .then((ret) => {
          if (ret.code == 1) {
            self.keywordList = ret.data
          }
        })
    },
    httpGetCartNumber() {
      var self = this
      self.common
        .httpPost(
          '/api/cart/myCartList',
          {
            noClear: 1,
          },
          false
        )
        .then((ret) => {
          if (ret.code == 1) {
            self.cartNumber = ret.data.enable.length
          }
        })
    },
    /**
     * 获取用户信息
     */
    getUserInfo() {
      var self = this
      self.common.httpPost('/api/user/getUserInfo', {}, false).then((ret) => {
        if (ret.code == 1) {
          self.userinfo = ret.data
          var locale = ret.data.local == 1 ? 'zhCHS' : 'zhCHT'
          self.$i18n.locale = locale

          sessionStorage.setItem('userinfo', JSON.stringify(ret.data))
        }
      })
    },
    fnCloseMask: function (val) {
      this[val] = false
    },
    httpWxLogin(code) {
      var self = this
      self.common
        .httpPost(
          '/api/ajax/wxLogin',
          {
            code: code,
          },
          false
        )
        .then((ret) => {
          self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
          if (ret.code == 1) {
            self.fnLoginSuccess({
              uuid: ret.data,
              local: ret.local,
            })
          } else {
            self.wxData = ret.data
          }
        })
    },
    fnLoginSuccess(data) {
      this.showLoginMask = false
      this.wxData = null
      localStorage.setItem('Wx1e2PtoP', data.uuid)
      localStorage.setItem('local', data.local)
      this.$i18n.locale = data.local == 1 ? 'zhCHS' : 'zhCHT'
      this.uuid = data.uuid
      window.location.href = '/dist/'
      // this.$router.replace({
      //     path: "/"
      // });
      // window.location.reload();
      // eventBus.$emit('EventUserLogin', data);
    },
    /** 搜索提交 */
    fnSearchSubmit() {
      if (!this.searchVal) return
      this.$router.push({
        path: '/search_result',
        query: {
          searchVal: this.searchVal,
        },
      })
    },
    /**
     * 设置密码成功
     */
    setPasswordSuccess() {
      this.showPwdMask = false
      this.showLoginMask = true
    },
    /** 跳转到留言*/
    fnToMessagePage() {
      var uuid = localStorage.getItem('Wx1e2PtoP')
      if (!uuid) return (this.showLoginMask = true)
      else this.wxData = null
      this.$router.push('/user/messageAdd')
      this.showLoginMask = false
    },
    /** 跳转个人中心*/
    fnToMemberPage() {
      var uuid = localStorage.getItem('Wx1e2PtoP')
      if (!uuid) return (this.showLoginMask = true)
      else this.fnToOrderList()
    },
    fnToOrderList() {
      this.wxData = null
      this.$router.push('/order/list')
      this.showLoginMask = false
    },
    /** 首页 */
    toHome() {
      this.$router.replace('/')
    },
    /* 购物车页面*/
    toCartPage() {
      this.$router.push('/cart')
    },
    /* 游客登录*/
    handleLoginTourist() {
      var self = this
      MessageBox({
        title: self.$t('header.language'),
        type: 'info',
        center: true,
        showCancelButton: true,
        showClose: false,
        confirmButtonText: self.$t('header.language_ch'),
        cancelButtonText: self.$t('header.language_hk'),
        cancelButtonClass: 'lang-btn',
        confirmButtonClass: 'lang-btn',
        closeOnClickModal: false,
        closeOnPressEscape: false,
        callback(ret) {
          self.$i18n.locale = ret == 'cancel' ? 'zhCHT' : 'zhCHS'
          self.showLoginMask = false
        },
      })
    },
    handleLoginToRegBox() {
      this.showLoginMask = false
      this.showRegMask = true
    },
    handleRegToLoginBox() {
      this.showRegMask = false
      this.showLoginMask = true
    },
    handlePwdToLoginBox() {
      this.showPwdMask = false
      this.showLoginMask = true
    },
    handleLoginToPwdBox() {
      this.showLoginMask = false
      this.showPwdMask = true
    },
    /**
     * 注册成功后执行
     */
    handleRegNext(data) {
      this.uuid = data.uuid
      eventBus.$emit('EventUserLogin', data)
      this.showRegMask = false
      this.showEmailMask = true
    },
    /* 跳过邮箱绑定*/
    handleBindEmailSkip() {
      this.showEmailMask = false
    },
  },
  watch: {
    $route(to, from) {
      this.uuid = localStorage.getItem('Wx1e2PtoP')
      if (this.$route.query.searchVal)
        this.searchVal = this.$route.query.searchVal
      else this.searchVal = null
    },
  },
  components: {
    'v-login': Login,
    'v-register': Register,
    'v-password': Password,
    'v-bind-email': BindEmail,
    'v-wechat-bind-mobile': WechatBindMobile,
  },
}
</script>
<style scoped>
@import '../assets/css/header.css';
</style>
<style>
.header {
  width: 1366px;
  margin: auto;
}

.lang-btn {
  color: white !important;
  background-color: #297fab !important;
}

.search-box-content form {
  width: 100%;
}

.message-button {
  width: 53px;
  height: 30px;
  background: rgba(41, 127, 171, 1);
  border-radius: 15px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 31px;
  text-align: center;
  margin-left: 43px;
}

.newMessage-box {
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: rgba(225, 36, 27, 1);
  line-height: 17px;
}

.newMessage-box img {
  height: 16px;
  width: 20px;
  margin-right: 5px;
}

.newMessage-box span {
  width: 186px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  position: relative;
  line-height: 17px;
  overflow: hidden;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; */
  /* border: 1px solid red; */
  white-space: nowrap;
  box-sizing: border-box;
  animation: marquee 5s linear infinite;
}

.newMessage-box span:hover {
  animation-play-state: paused;
}

/* Make it move */
@keyframes marquee {
  0% {
    text-indent: 120%;
  }
  100% {
    text-indent: -100%;
  }
}
</style>