import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import i18n from './i18n'
// import '@babel/polyfill'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

window.eventBus = new Vue() //注册全局事件对象
Vue.config.productionTip = false

/* 自定义公用函数 */
const common = require('./assets/js/common.js')
Vue.prototype.common = common

Vue.filter('showDiscountName', function(value) {
  if (!value) return ''
  return value.length <= 6 ? value : value.substring(0, 6) + '..'
})

Vue.filter('traditional', function(value) {
  return common.toTraditional(value, 2)
})

Vue.config.devtools = true

window.vm = new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
