<template>
  <div id="app">
    <v-header></v-header>
    <v-navigation></v-navigation>
    <router-view />
    <v-footer></v-footer>
  </div>
</template>
<script>
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";
import Navigation from "./components/navigation.vue";
export default {
  name: "app",
  created() {
    this.getSystemNumber();

    // 页面空白地方点击事件
    window.addEventListener("click", function() {
      eventBus.$emit("pageClick");
    });

    //禁止后退键 作用于Firefox、Opera
    document.onkeypress = this.forbidBackSpace;
    //禁止后退键  作用于IE、Chrome
    document.onkeydown = this.forbidBackSpace;
  },
  methods: {
    getSystemNumber: function() {
      var self = this;
      self.common
        .httpPost("/api/ajax/getSystemNumber", null, false)
        .then(ret => {
          if (ret.code == 1) {
            ret.data.forEach(item => {
              localStorage.setItem(item.key, item.value);
            });
          }
        });
    },
    forbidBackSpace: function(e) {
      var ev = e || window.event; //获取event对象
      var obj = ev.target || ev.srcElement; //获取事件源
      var t = obj.type || obj.getAttribute("type"); //获取事件源类型
      //获取作为判断条件的事件类型
      var vReadOnly = obj.readOnly;
      var vDisabled = obj.disabled;
      //处理undefined值情况
      vReadOnly = vReadOnly == undefined ? false : vReadOnly;
      vDisabled = vDisabled == undefined ? true : vDisabled;
      //当敲Backspace键时，事件源类型为密码或单行、多行文本的，
      //并且readOnly属性为true或disabled属性为true的，则退格键失效
      var flag1 =
        ev.keyCode == 8 &&
        (t == "password" || t == "text" || t == "textarea") &&
        (vReadOnly == true || vDisabled == true);
      //当敲Backspace键时，事件源类型非密码或单行、多行文本的，则退格键失效
      var flag2 =
        ev.keyCode == 8 && t != "password" && t != "text" && t != "textarea";
      //判断
      if (flag2 || flag1) return false;
    }
  },
  components: {
    "v-header": Header,
    "v-footer": Footer,
    "v-navigation": Navigation
  }
};
</script>
<style>
/* @import './assets/font/font.css'; */

html,
body,
header,
section,
footer,
div,
ul,
ol,
li,
img,
a,
span,
em,
del,
legend,
center,
strong,
var,
fieldset,
form,
label,
dl,
dt,
dd,
cite,
input,
hr,
time,
mark,
code,
figcaption,
figure,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  border: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, Tahoma, "Microsoft Yahei",
    "PingFang SC", "Hiragino Sans GB", "WenQuanYi Micro Hei", sans-serif;
  /* font-family: "helvetica neue", arial, helvetica, "Hiragino Sans GB", "STHeiti Light", "SimSun", sans-serif; */
  /* font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu,"Helvetica Neue",Arial,sans-serif; */
  /* font-family: "PingFangSC-Medium", "PingFang SC", "PingFang", "PingFangSC-Regular", sans-serif; */
  /* font-family: "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif; */
}

img {
  -o-object-fit: contain;
  object-fit: contain;
}

body {
  padding-right: 0 !important;
}

html,
body {
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
}

textarea,
input {
  overflow: hidden;
  resize: none;
  outline: none;
}

body {
  width: 100%;
  margin: 0 auto;
}

p {
  margin: 0;
  padding: 0;
}

.center-wrap {
  width: 1366px;
  margin: auto;
}

.click {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.empty {
  display: flex;
  justify-content: center;
  position: relative;
  width: 250px;
  height: 250px;
  margin: 125px auto;
}

.empty img {
  width: 250px;
  height: 250px;
}

.empty p {
  color: #b3b3b3;
  font-size: 15px;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
}

.el-pagination button span {
  padding: 0 10px;
}

.el-breadcrumb .el-breadcrumb__inner {
  color: #333;
  font-size: 16px;
}

.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  color: #333 !important;
  font-size: 16px;
  font-weight: 400 !important;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #333 !important;
  font-size: 16px;
}

.el-input input {
  line-height: 20px !important;
}
</style>