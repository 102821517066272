import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决重复导航的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '首頁',
    },
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../views/download/download.vue'),
    meta: {
      title: '下载',
    },
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import('../views/member.vue'),
    redirect: '/member/order',
    children: [
      {
        path: 'order',
        name: 'MemberOrder',
        component: () => import('../views/member/MemberOrder.vue'),
      },
      {
        path: 'balance',
        name: 'MemberBalance',
        component: () => import('../views/member/MemberBalance.vue'),
      },
      {
        path: 'address',
        name: 'MemberAddress',
        component: () => import('../views/member/MemberAddress.vue'),
      },
      {
        path: 'password',
        name: 'MemberPassword',
        component: () => import('../views/member/MemberPassword.vue'),
      },
      {
        path: 'pay-password',
        name: 'MemberPayPassword',
        component: () => import('../views/member/MemberPayPassword.vue'),
      },
    ],
  },
  {
    path: '/textinfo',
    name: 'TextInfo',
    component: () => import('../views/TextInfo.vue'),
  },
  {
    path: '/user/paypassword',
    name: 'UserPayPassword',
    meta: {
      title: '修改支付密碼',
      needLogin: true,
    },
    component: () => import('../views/UserPayPassword.vue'),
  },
  {
    path: '/order/alipay_hk',
    name: 'AliPayHK',
    meta: {
      title: '支付寶付款',
      needLogin: true,
    },
    component: () => import('../views/AliPayHK.vue'),
  },
  {
    path: '/user/password',
    name: 'UserLoginPassword',
    meta: {
      title: '修改登錄密碼',
      needLogin: true,
    },
    component: () => import('../views/UserLoginPassword.vue'),
  },
  {
    path: '/recharge/result',
    name: 'RechargeResult',
    meta: {
      title: '在綫充值',
      needLogin: true,
    },
    component: () => import('../views/RechargeResult.vue'),
  },
  {
    path: '/user/cashout',
    name: 'UserCashout',
    meta: {
      title: '用戶提現',
      needLogin: true,
    },
    component: () => import('../views/UserCashout.vue'),
  },
  {
    path: '/user/recharge',
    name: 'userRecharge',
    meta: {
      title: '在綫充值',
      needLogin: true,
    },
    component: () => import('../views/UserRecharge.vue'),
  },
  {
    path: '/user/balance',
    name: 'userBalance',
    meta: {
      title: '我的餘額',
      needLogin: true,
    },
    component: () => import('../views/UserBalance.vue'),
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '關於我們',
    },
    component: () => import('../views/About.vue'),
  },
  {
    path: '/examine',
    name: 'examine',
    meta: {
      title: '審核詳情',
      needLogin: true,
    },
    component: () => import('../views/Examine.vue'),
  },
  {
    path: '/feedback',
    name: 'feedback',
    meta: {
      title: '用戶反饋',
      needLogin: true,
    },
    component: () => import('../views/Feedback.vue'),
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      title: '商品詳情',
    },
    component: () => import('../views/product.vue'),
  },
  {
    path: '/search_result',
    name: 'searchResult',
    meta: {
      title: '搜索結果',
    },
    component: () => import('../views/SerachResult.vue'),
  },
  {
    path: '/cart',
    name: 'cart',
    meta: {
      title: '購物車',
      needLogin: true,
    },
    component: () => import('../views/Cart.vue'),
  },
  {
    path: '/orderConfirm',
    name: 'orderConfirm',
    meta: {
      title: '確認訂單',
      needLogin: true,
    },
    component: () => import('../views/OrderConfirm.vue'),
  },
  {
    path: '/transfer',
    name: 'transferEvidence',
    meta: {
      title: '上傳轉賬憑證',
      needLogin: true,
    },
    component: () => import('../views/TransferEvidence.vue'),
  },
  {
    path: '/list',
    name: 'productList',
    meta: {
      title: '商品列表',
    },
    component: () => import('../views/ProductList.vue'),
  },
  {
    path: '/activity',
    name: 'productList',
    meta: {
      title: '商品活動',
    },
    component: () => import('../views/ProductActivity.vue'),
  },
  {
    path: '/order/list',
    name: 'orderList',
    meta: {
      title: '訂單列表',
      needLogin: true,
    },
    component: () => import('../views/OrderList.vue'),
  },
  {
    path: '/user/record',
    name: 'CustomerRecord',
    meta: {
      title: '客户备案',
      needLogin: true,
    },
    component: () => import('../views/CustomerRecord.vue'),
  },

  {
    path: '/user/address',
    name: 'userAddress',
    meta: {
      title: '我的收貨地址',
      needLogin: true,
    },
    component: () => import('../views/UserAddress.vue'),
  },
  {
    path: '/user/info',
    name: 'userInfo',
    meta: {
      title: '我的資料',
      needLogin: true,
    },
    component: () => import('../views/UserInfo.vue'),
  },
  {
    path: '/order/detail',
    name: 'orderDetail',
    meta: {
      title: '訂單詳情',
      needLogin: true,
    },
    component: () => import('../views/OrderDetail.vue'),
  },
  {
    path: '/order/logistics',
    name: 'OrderLogistics',
    meta: {
      title: '查看物流',
      needLogin: true,
    },
    component: () => import('../views/logistics.vue'),
  },
  {
    path: '/transfer/info',
    name: 'transferInfo',
    meta: {
      title: '查看轉賬信息',
      needLogin: true,
    },
    component: () => import('../views/TransferInfo.vue'),
  },
  {
    path: '/user/messageList',
    name: 'userMessageList',
    meta: {
      title: '留言列表',
      needLogin: true,
    },
    component: () => import('../views/UserMessageList.vue'),
  },
  {
    path: '/user/messageAdd',
    name: 'userMessageAdd',
    meta: {
      title: '發佈留言',
      needLogin: true,
    },
    component: () => import('../views/UserMessageAdd.vue'),
  },
  {
    path: '/user/messageReply',
    name: 'userMessageReply',
    meta: {
      title: '回復反饋',
      needLogin: true,
    },
    component: () => import('../views/UserMessageReply'),
  },
  {
    path: '/user/messageDetail',
    name: 'userMessageDetail',
    meta: {
      title: '留言詳情',
      needLogin: true,
    },
    component: () => import('../views/UserMessageDetail.vue'),
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// const loginStatePage = [
//     "UserPayPassword","UserLoginPassword",""
// ];

router.beforeEach((to, from, next) => {
  const isMobile = require('../utils/size').isMobile()
  if (isMobile) {
    window.location.href = 'https://mz2c.com/download.html'
  } else {
    if (to.meta.title) document.title = to.meta.title
    let uuid = localStorage.getItem('Wx1e2PtoP')
    if (uuid) {
      //beforeEach是router的钩子函数，在进入路由前执行
      next() //执行进入路由，如果不写就不会进入目标页
    } else {
      if (to.meta.needLogin) {
        eventBus.$emit('userLoginIntercept')
      } else next()
    }
  }
})

router.afterEach((to, from) => {
  window.scrollTo(0, 0)
})

export default router
