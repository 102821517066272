<template>
    <div class="register">
        <div class="bigbox">
            <div class="login-box">
                <div class="title-box">
                    <div v-text="$t('reg.title')">注册</div>
                    <img src="../assets/icon/pc.png" @click.stop="toLoginBox">
                </div>
                <div class="input-box">
                    <div>
                        <span v-if="area">+{{area == 1?'86':'852'}}</span>
                        <input type="text" :placeholder="$t('login.mobile_placeholder')" class="text-input" maxlength="15" v-model="mobile" v-on:input="valCheck(/[^0-9]/g,'mobile')">
                    </div>
                    <div class="testing-num">
                        <input type="text" :placeholder="$t('login.code_placeholder')" maxlength="4" v-model="mobile_code" v-on:input="valCheck(/[^0-9]/g,'mobile_code')">
                        <div class="click sms-code-btn" @click.stop="fnGetSmsCode()" v-text="smsBtnText"></div>
                    </div>
                    <div>
                        <input type="text" :placeholder="$t('reg.levelcode')" class="text-input" maxlength="5" v-model="level_code">
                    </div>
                    <div>
                        <input type="password" :placeholder="$t('password.password_placeholder')" class="text-input" maxlength="16" v-model="password" v-on:input="valCheck(/[^a-zA-z0-9]/g,'password')">
                    </div>
                    <div>
                        <input type="password" :placeholder="$t('password.repassword_placeholder')" class="text-input" maxlength="16" v-model="repassword" v-on:input="valCheck(/[^a-zA-z0-9]/g,'repassword')">
                    </div>
                </div>
                <div class="text-box">
                    <div class="select click" @click.stop="area=1">
                        <img src="../assets/icon/yes-select.png" v-if="area==1">
                        <img src="../assets/icon/no-select.png" v-else>
                        <p v-text="$t('reg.area_china')">大陆地区</p>
                    </div>
                    <div class="select click" @click.stop="area=2">
                        <img src="../assets/icon/yes-select.png" v-if="area==2">
                        <img src="../assets/icon/no-select.png" v-else>
                        <p v-text="$t('reg.area_china_hk')">香港地区</p>
                    </div>
                </div>
                <h1 v-text="$t('reg.remark')">选择不同注册地区将影响到可购买到的商品及支付货代，注册之后不可修改地区，请谨慎选择。</h1>
                <div class="login click" @click.stop="fnNext" v-text="$t('reg.next')">下一步</div>
                <div class="select">
                    <img src="../assets/icon/yes-select.png" class="click" v-if="userAgreement" @click.stop="userAgreement = !userAgreement">
                    <img src="../assets/icon/no-select.png" class="click" v-else @click.stop="userAgreement = !userAgreement">
                    <h2 class="click">
                        <span @click.stop="userAgreementPage($t('login.user_agreement'),'user_agreement')" v-text="$t('login.user_agreement')"></span>和
                        <span @click.stop="userAgreementPage($t('login.privacy_agreement'),'privacy_policy')" v-text="$t('login.privacy_agreement')"></span>
                    </h2>
                </div>
                <img src="../assets/icon/icon-close.png" class="close-icon" @click.stop="fnClose">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Register',
    data() {
        return {
            area: null, // 大陆地区/香港地区
            userAgreement: false, // 是否用户用户协议
            mobile: null,
            level_code: null, //等级码
            password: null,
            repassword: null,
            mobile_code: null,
            smsBtnText: this.$t('login.getcode'), // 获取验证码
            sendSmsIng: false,
        }
    },
    watch: {
        area: function() {
            if (this.area == 1) {
                this.$i18n.locale = 'zhCHS';
            }
            if (this.area == 2) {
                this.$i18n.locale = 'zhCHT';
            }

            this.smsBtnText = this.$t('login.getcode');
        }
    },
    methods: {
        /** 获取验证码 */
        fnGetSmsCode() {
            var self = this;
            if (self.sendSmsIng) return false;
            if (self.checkMobile() != "success") return;
            self.httpSendSms(self.mobile).then(ret => {
                if (ret.code == 1) {
                    self.common.showMessage(self.$t('common.send_success'), "success");
                    self.fnSmsBtnTim();
                } else self.common.showMessage(self.$t('common.send_error'), "error");
            })
        },
        checkMobile() {
            if (!this.mobile) return this.common.showMessage(this.$t('login.input_mobile'), "error");
            if (!this.common.isTelAvailable(this.mobile)) return this.common.showMessage(this.$t('login.mobile_format_error'), "error");
            return "success";
        },
        /**
         * 发送短信验证码
         * @param  {[type]}   mobile 手机号码
         * @param  {Function} fn     回调函数
         * @return {[type]}          [description]
         */
        httpSendSms: function(mobile) {
            var self = this;
            return self.common.httpPost("/api/ajax/sendMobileMessage", {
                mobile: mobile
            })
        },
        // 短信按钮计时
        fnSmsBtnTim: function() {
            var self = this;
            var time = 60;
            var timer = setInterval(function() {
                if (time > 0) {
                    self.sendSmsIng = true;
                    self.smsBtnText = time + "s";
                    time--;
                } else {
                    self.smsBtnText = self.$t('login.getcode');
                    self.sendSmsIng = false;
                    clearInterval(timer);
                }
            }, 1000);
        },
        toLoginBox() {
            this.$emit("toLoginBox")
        },
        /* 下一步*/
        fnNext() {
            //注册....
            var self = this;
            if (self.checkMobile() != "success") return;

            if (!self.mobile_code) return this.common.showMessage(self.$t('login.code_placeholder'), "error"); // 请输入验证码
            if (self.mobile_code.length !== 4) return this.common.showMessage(self.$t('login.code_error'), "error"); // 验证码错误
            if (!self.password) return this.common.showMessage(self.$t('login.please_password'), "error"); // 请输入6-16位密码
            if (self.password.length < 6) return this.common.showMessage(self.$t('login.please_password'), "error"); //请输入6-16位密码
            if (self.password != self.repassword) return this.common.showMessage(self.$t('password.no_alike'), "error"); // 确认密码不正确
            if (!self.userAgreement) return this.common.showMessage(self.$t('login.please_hook'), "error");
            if (!self.area) return this.common.showMessage(self.$t('reg.choose_area'), "error");

            self.common.httpPost("/api/ajax/register", {
                mobile: self.mobile,
                mobile_code: self.mobile_code,
                local: self.area,
                password: self.password,
                level_code: self.level_code
            }).then((ret => {
                if (ret.code == 1) {
                    self.common.showMessage(ret.msg, "success");
                    // 注册成功 -> 绑定邮箱
                    var uuid = ret.data;
                    localStorage.setItem("Wx1e2PtoP", uuid);
                    localStorage.setItem("local", ret.local);
                    self.$emit('fnNext', { uuid: uuid, local: ret.local });
                } else self.common.showMessage(ret.msg, "error");
            }))
        },
        valCheck: function(str, val) {
            this[val] = this[val].replace(str, '');
        },
        fnClose() {
            this.$emit("close");
        },
        /**
         * 打开用户协议页面
         */
        userAgreementPage: function(title,key) {
            let routeUrl = this.$router.resolve({
                path: "/textinfo",
                query: { key: key, title: title },
                meta: {
                    title: title
                }
            });
            window.open(routeUrl.href, '_blank');
        }
    }
}
</script>
<style scoped>
.bigbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 99;
}

.login-box {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 650px;
    width: 340px;
    background: #FFFFFF;
    padding-left: 30px;
    padding-right: 30px;
}

.close-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    top: -46px;
    right: 0px;
}


.title-box {
    border-bottom: 1px solid rgba(204, 204, 204, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-box div {
    width: 68px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    line-height: 74px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.7);
}

.title-box img {
    height: 49px;
    width: 49px;
}

.testing-num {
    height: 42px;
    width: 340px;
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 0.7);
    display: flex;
    overflow: hidden;
}

.testing-num .sms-code-btn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(41, 127, 171, 1);
    margin: 0;
    padding: 0;
}

.testing-num input {
    height: 42px;
    width: 193px;
    padding-left: 20px;
    /* padding-right: 20px; */
    background: rgba(244, 244, 244, 1);
    height: 20px;
    line-height: 20px;
}

.testing-num div {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    color: rgba(204, 204, 204, 1);
    line-height: 42px;
    text-align: center;
}

.input-box {
    margin-top: 44px;
}

.input-box div {
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    background: rgba(244, 244, 244, 1);
    border-radius: 4px;
    height: 42px;
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    border: 1px solid rgba(221, 221, 221, 0.7);
}

.input-box div input {
    line-height: 20px;
    height: 20px;
    /* border: 1px solid red; */
}

.input-box div span {
    margin-left: 20px;
    margin-right: 5px;
    font-size: 12px;
}

.text-input {
    height: 42px;
    line-height: 42px;
    width: 300px;
    padding-left: 20px;
    background: rgba(244, 244, 244, 1);
}


.text-box {
    display: flex;
    align-items: center;
}

.select {
    display: flex;
    align-items: center;
    margin-right: 33px;
}

.select img {
    height: 14px;
    width: 14px;
    margin-right: 7px;
}

.select p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 16px;
}

.select h2 span{
    color: #297fab;
}

h1 {
    font-size: 12px;
    font-weight: 400;
    color: rgba(156, 192, 210, 1);
    line-height: 17px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.login {
    height: 42px;
    width: 340px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 42px;
    background: rgba(41, 127, 171, 1);
    border-radius: 4px;
    margin-bottom: 20px;
}

h2 {
    font-size: 14px;
    font-weight: 400;
    color: rgba(179, 179, 179, 1);
    line-height: 16px;
}
</style>