<template>
  <div class="login">
    <div class="bigbox">
      <!-- 手机号密码登录 -->
      <transition name="el-fade-in-linear">
        <div class="login-box" v-if="loginType == 'password'">
          <div class="title-box">
            <div>
              <div class="yes-botton click" v-text="$t('login.typePwd')">密码登录</div>
              <div
                class="no-botton click"
                @click.stop="loginType='smscode'"
                v-text="$t('login.typeQuick')"
              >快捷登录</div>
            </div>
            <!-- <img src="../assets/icon/code.png" class="click" @click.stop="switchWxLogin"> -->
          </div>
          <div class="input-box">
            <div>
              <input
                type="text"
                :placeholder="$t('login.mobile_placeholder')"
                maxlength="15"
                v-model="mobile"
              />
            </div>
            <div>
              <input
                type="password"
                :placeholder="$t('login.password_placeholder')"
                maxlength="20"
                v-model="password"
              />
            </div>
            <div
              class="login-btn click"
              @click.stop="loginPassword"
              v-text="$t('login.login_btn')"
            >登录</div>
          </div>
          <div class="text-box">
            <div class="select">
              <img
                src="../assets/icon/yes-select.png"
                class="click"
                v-if="userAgreement"
                @click.stop="userAgreement = !userAgreement"
              />
              <img
                src="../assets/icon/no-select.png"
                class="click"
                v-else
                @click.stop="userAgreement = !userAgreement"
              />
              <p class="click">
                <span
                  @click.stop="userAgreementPage($t('login.user_agreement'),'user_agreement')"
                  v-text="$t('login.user_agreement')"
                ></span>和
                <span
                  @click.stop="userAgreementPage($t('login.privacy_agreement'),'privacy_policy')"
                  v-text="$t('login.privacy_agreement')"
                ></span>
              </p>
            </div>
            <div class="other">
              <p class="click" @click.stop="toPasswordBox" v-text="$t('login.forget')">忘记密码</p>
              <div></div>
              <p class="click" @click.stop="toRegBox" v-text="$t('login.register')">注册</p>
            </div>
          </div>
          <img src="../assets/icon/icon-close.png" class="close-icon" @click.stop="fnClose" />
        </div>
      </transition>

      <!-- 手机号短信验证码登录 -->
      <transition name="el-fade-in-linear">
        <div class="login-box" v-if="loginType == 'smscode'">
          <div class="title-box">
            <div>
              <div
                class="no-botton click"
                @click.stop="loginType='password'"
                v-text="$t('login.typePwd')"
              >密码登录</div>
              <div class="yes-botton click" v-text="$t('login.typeQuick')">快捷登录</div>
            </div>
            <!-- <img src="../assets/icon/code.png" class="click" @click.stop="switchWxLogin"> -->
          </div>
          <div class="input-box">
            <div>
              <input
                type="text"
                :placeholder="$t('login.mobile_placeholder')"
                maxlength="15"
                v-model="mobile"
              />
            </div>
            <div class="sms-code-box">
              <input
                type="text"
                :placeholder="$t('login.code_placeholder')"
                maxlength="4"
                v-model="mobile_code"
              />
              <div class="send-code-btn click" @click.stop="fnGetSmsCode()" v-text="smsBtnText"></div>
            </div>
            <div
              class="login-btn click"
              @click.stop="loginSmsCode"
              v-text="$t('login.login_btn')"
            >登录</div>
            <!-- <div class="quick-login click" v-text="$t('login.login_tourist_btn')">游客登录</div> -->
          </div>
          <div class="text-box">
            <div class="select">
              <img
                src="../assets/icon/yes-select.png"
                class="click"
                v-if="userAgreement"
                @click.stop="userAgreement = !userAgreement"
              />
              <img
                src="../assets/icon/no-select.png"
                class="click"
                v-else
                @click.stop="userAgreement = !userAgreement"
              />
              <p class="click">
                <span
                  @click.stop="userAgreementPage($t('login.user_agreement'),'user_agreement')"
                  v-text="$t('login.user_agreement')"
                ></span>和
                <span
                  @click.stop="userAgreementPage($t('login.privacy_agreement'),'privacy_policy')"
                  v-text="$t('login.privacy_agreement')"
                ></span>
              </p>
            </div>
            <div class="other">
              <p class="click" @click.stop="toRegBox" v-text="$t('login.register')">注册</p>
            </div>
          </div>
          <img src="../assets/icon/icon-close.png" class="close-icon" @click.stop="fnClose" />
        </div>
      </transition>

      <!-- 微信扫码登录 -->
      <!-- <transition name="el-fade-in-linear">
        <div class="login-box" v-if="loginType == 'wechat'">
          <div class="title-box">
            <h1 v-text="$t('login.typeWechat')">微信登录</h1>
            <img src="../assets/icon/pc.png" class="click" @click.stop="loginType='password'" />
          </div>
          <div id="qr-code"></div>
          <div class="renovate">
            <img src="../assets/icon/renovate.png" class="click" />
            <p class="click" v-text="$t('login.qrcode_refresh')" @click.stop="createQrCode">点击刷新二维码</p>
          </div>
          <h2 class="click" v-text="$t('login.typeQuick')" @click.stop="loginType='smscode'">快捷登录</h2>
          <img src="../assets/icon/icon-close.png" class="close-icon" @click.stop="fnClose" />
        </div>
      </transition>-->
    </div>
  </div>
</template>
<script>
export default {
  name: 'Login',
  data() {
    return {
      loginType: 'password', //password手机号密码 smscode验证码登录  wechat微信登录
      userAgreement: false, // 是否用户用户协议
      mobile: null,
      password: null,
      mobile_code: null,
      smsBtnText: this.$t('login.getcode'), // 获取验证码
      sendSmsIng: false,
    }
  },
  created() {},
  methods: {
    fnClose() {
      this.$emit('close')
    },
    /** 切换微信登录 */
    switchWxLogin() {
      this.loginType = 'wechat'
      this.createQrCode()
    },
    /**
     * 创建微信登录二维码
     */
    createQrCode() {
      this.$nextTick(() => {
        var redirect_uri = window.location.href
        var obj = new WxLogin({
          id: 'qr-code',
          appid: 'wx74bad22ff51afd8a',
          scope: 'snsapi_login',
          redirect_uri: encodeURI('https://mz2c.com/dist/#/'),
          state: '000',
          style: 'black',
          href: 'https://mz2c.com/wxlogin.css',
        })
      })
    },
    /** 手机号密码登录 */
    loginPassword() {
      var self = this
      if (self.checkMobile() != 'success') return
      if (!self.password)
        return this.common.showMessage(
          self.$t('login.please_password'),
          'error'
        ) // 请输入6-16位密码
      if (!self.userAgreement)
        return this.common.showMessage(self.$t('login.please_hook'), 'error')
      self.common
        .httpPost('/api/ajax/login', {
          mobile: self.mobile,
          password: self.password,
        })
        .then((ret) => {
          self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
          if (ret.code == 1) self.fnLoginSuccess(ret.data, ret.local)
        })
    },
    /** 手机号 验证码 登录 */
    loginSmsCode() {
      var self = this
      if (self.checkMobile() != 'success') return
      if (!self.mobile_code)
        return self.common.showMessage(self.$t('login.input_code'), 'error')
      if (self.mobile_code.length != 4)
        return self.common.showMessage(self.$t('login.code_error'), 'error')
      if (!self.userAgreement)
        return this.common.showMessage(self.$t('login.please_hook'), 'error')

      self.common
        .httpPost('/api/ajax/fastLogin', {
          mobile: self.mobile,
          mobile_code: self.mobile_code,
        })
        .then((ret) => {
          self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
          if (ret.code == 1) self.fnLoginSuccess(ret.data, ret.local)
        })
    },
    /**
     * 登录成功
     */
    fnLoginSuccess(uuid, local) {
      this.$emit('fnLoginSuccess', { uuid: uuid, local: local })
    },
    /** 获取验证码 */
    fnGetSmsCode() {
      var self = this
      if (self.sendSmsIng) return false
      if (this.checkMobile() != 'success') return
      self.httpSendSms(self.mobile).then((ret) => {
        if (ret.msg == '手机号码未注册')
          return self.common.showMessage('手機號碼未註冊', 'error')
        self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
        if (ret.code == 1) self.fnSmsBtnTim()
      })
    },
    checkMobile() {
      if (!this.mobile)
        return this.common.showMessage(this.$t('login.input_mobile'), 'error')
      if (!this.common.isTelAvailable(this.mobile))
        return this.common.showMessage(
          this.$t('login.mobile_format_error'),
          'error'
        )
      return 'success'
    },
    /**
     * 发送短信验证码
     * @param  {[type]}   mobile 手机号码
     * @param  {Function} fn     回调函数
     * @return {[type]}          [description]
     */
    httpSendSms: function (mobile) {
      return this.common.httpPost('/api/ajax/sendMobileMessage', {
        mobile: mobile,
        action: 'password',
      })
    },
    // 短信按钮计时
    fnSmsBtnTim: function () {
      var self = this
      var time = 60
      var timer = setInterval(function () {
        if (time > 0) {
          self.sendSmsIng = true
          self.smsBtnText = time + 's'
          time--
        } else {
          self.smsBtnText = self.$t('login.getcode')
          self.sendSmsIng = false
          clearInterval(timer)
        }
      }, 1000)
    },
    /** 注册 */
    toRegBox() {
      this.$emit('toRegBox')
    },
    toPasswordBox() {
      this.$emit('toPasswordBox')
    },
    /* 游客登录*/
    loginTourist() {
      this.$emit('loginTourist')
    },
    /**
     * 打开用户协议页面
     */
    userAgreementPage: function (title, key) {
      let routeUrl = this.$router.resolve({
        path: '/textinfo',
        query: { key: key, title: title },
        meta: {
          title: title,
        },
      })
      window.open(routeUrl.href, '_blank')
    },
  },
}
</script>
<style scoped>
.bigbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 199;
}

#qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* height: 470px; */
  width: 340px;
  background: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.close-icon {
  width: 32px;
  height: 32px;
  position: absolute;
  top: -46px;
  right: 0px;
}

.title-box {
  border-bottom: 1px solid rgba(204, 204, 204, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-box img {
  height: 60px;
  width: 60px;
}

.title-box > div {
  display: flex;
}

.yes-botton {
  width: 68px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 75px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.7);
  margin-right: 30px;
}

.no-botton {
  width: 68px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 75px;
  margin-right: 30px;
}

.input-box {
  margin-top: 44px;
}

.input-box div {
  margin-bottom: 22px;
  border-radius: 4px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  background: rgba(244, 244, 244, 1);
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-box div input {
  height: 20px;
  line-height: 20px;
}

.input-box div.sms-code-box {
  display: flex;
  align-items: center;
}

.input-box div.sms-code-box .send-code-btn {
  height: 42px;
  margin-bottom: 0px;
  width: 106px;
  line-height: 42px;
  text-align: center;
  font-size: 14px;
  color: rgba(41, 127, 171, 1);
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid rgba(221, 221, 221, 0.7);
}

.input-box input {
  height: 42px;
  line-height: 42px;
  width: 300px;
  background: rgba(244, 244, 244, 1);
  flex: 1;
  border: none;
  margin: 0 20px;
}

.input-box .login-btn {
  height: 42px;
  width: 340px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 42px;
  background: rgba(41, 127, 171, 1);
  border-radius: 4px;
}

.input-box .quick-login {
  height: 42px;
  width: 340px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 42px;
  border: 1px solid rgba(41, 127, 171, 0.7);
  border-radius: 4px;
  background-color: white;
}

.text-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select {
  display: flex;
  align-items: center;
}

.select img {
  height: 14px;
  width: 14px;
  margin-right: 7px;
}

.select p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 16px;
}

.select p span {
  color: #297fab;
}

.other {
  display: flex;
  align-items: center;
}

.other p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 16px;
}

.other div {
  width: 1px;
  height: 14px;
  background: #666666;
  margin-left: 10px;
  margin-right: 10px;
}

h1 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 75px;
}

.code {
  height: 190px;
  width: 190px;
  margin: 30px 75px 20px;
}

.renovate {
  display: flex;
  justify-content: center;
  align-items: center;
}

.renovate img {
  height: 18px;
  width: 18px;
  margin-right: 7px;
}

.renovate p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 16px;
}

h2 {
  margin-top: 55px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 16px;
}
</style>