<!-- 找回密码 -->
<template>
    <div class="password">
        <div class="bigbox">
            <div class="login-box">
                <div class="title-box">
                    <div v-text="$t('password.title')">找回密码</div>
                    <img src="../assets/icon/pc.png" @click.stop="toLoginBox">
                </div>
                <div class="input-box">
                    <div>
                        <input type="text" :placeholder="$t('password.mobile_placeholder')" class="text-input" maxlength="11" v-model="mobile">
                    </div>
                    <div class="testing-num">
                        <input type="text" :placeholder="$t('password.code_placeholder')" maxlength="4" v-model="mobile_code">
                        <div class="click sms-code-btn" @click.stop="fnGetSmsCode()" v-text="smsBtnText"></div>
                    </div>
                    <div>
                        <input type="password" :placeholder="$t('password.password_placeholder')" class="text-input" maxlength="20" v-model="password">
                    </div>
                    <div>
                        <input type="password" :placeholder="$t('password.repassword_placeholder')" class="text-input" maxlength="20" v-model="repassword">
                    </div>
                </div>
                <div class="login click" v-text="$t('password.commit_btn')" @click.stop="passwordSubmit">提交</div>
                <img src="../assets/icon/icon-close.png" class="close-icon" @click.stop="fnClose">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Password',
    data() {
        return {
            mobile: null,
            password: null,
            repassword: null,
            mobile_code: null,
            smsBtnText: this.$t('login.getcode'), // 获取验证码
            sendSmsIng: false,
        }
    },
    methods: {
        passwordSubmit() {
            var self = this;
            if (self.checkMobile() != "success") return;
            if (!self.mobile_code) return this.common.showMessage(self.$t('login.code_placeholder'), "error"); // 请输入验证码
            if (self.mobile_code.length !== 4) return this.common.showMessage(self.$t('login.code_error'), "error"); // 验证码错误
            if (!self.password || self.password.length < 6) return this.common.showMessage(self.$t('login.please_password'), "error"); //请输入6-16位密码
            if (self.password != self.repassword) return this.common.showMessage(self.$t('password.no_alike'), "error"); // 确认密码不正确

            self.common.httpPost("/api/ajax/forgetPassword", {
                mobile: self.mobile,
                mobile_code: self.mobile_code,
                password: self.password
            }).then((ret => {
                self.common.showMessage(ret.msg, ret.code == 1 ? "success" : "error");
                if (ret.code == 1) {
                    self.$emit('setPasswordSuccess');
                }
            }))

        },
        /** 获取验证码 */
        fnGetSmsCode() {
            var self = this;
            if (self.sendSmsIng) return false;
            if (this.checkMobile() != "success") return;
            self.httpSendSms(self.mobile).then(ret => {
                if (ret.msg == '手机号码未注册') return self.common.showMessage('手機號碼未註冊', "error");
                self.common.showMessage(ret.code == 1 ? self.$t('common.send_success') : self.$t('common.send_error'), ret.code == 1 ? "success" : "error");
                if (ret.code == 1) self.fnSmsBtnTim();
            })
        },
        checkMobile() {
            if (!this.mobile) return this.common.showMessage(this.$t('login.input_mobile'), "error");
            if (!this.common.isTelAvailable(this.mobile)) return this.common.showMessage(this.$t('login.mobile_format_error'), "error");
            return "success";
        },
        /**
         * 发送短信验证码
         * @param  {[type]}   mobile 手机号码
         * @param  {Function} fn     回调函数
         * @return {[type]}          [description]
         */
        httpSendSms: function(mobile) {
            return this.common.httpPost("/api/ajax/sendMobileMessage", {
                mobile: mobile,
                action: "password"
            })
        },
        // 短信按钮计时
        fnSmsBtnTim: function() {
            var self = this;
            var time = 60;
            var timer = setInterval(function() {
                if (time > 0) {
                    self.sendSmsIng = true;
                    self.smsBtnText = time + "s";
                    time--;
                } else {
                    self.smsBtnText = self.$t('login.getcode');
                    self.sendSmsIng = false;
                    clearInterval(timer);
                }
            }, 1000);
        },
        toLoginBox() {
            this.$emit("toLoginBox")
        },
        fnClose() {
            this.$emit("close");
        }
    }
}
</script>
<style scoped>
.bigbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 66;
}

.login-box {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 490px;
    width: 340px;
    background: #FFFFFF;
    padding-left: 30px;
    padding-right: 30px;
}


.close-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    top: -46px;
    right: 0px;
}


.title-box {
    border-bottom: 1px solid rgba(204, 204, 204, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-box div {
    width: 68px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    line-height: 74px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.7);
}

.title-box img {
    height: 49px;
    width: 49px;
}

.testing-num {
    height: 42px;
    width: 340px;
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 0.7);
    display: flex;
    overflow: hidden;
}

.testing-num .sms-code-btn {
    color: rgba(41, 127, 171, 1);
    height: 42px;
    margin: 0;
    display: flex;
    align-items: center;
    border: none;
    justify-content: center;
}

.testing-num input {
    height: 42px;
    width: 193px;
    padding-left: 20px;
    padding-right: 20px;
    background: rgba(244, 244, 244, 1);
}

.testing-num div {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    color: rgba(204, 204, 204, 1);
    line-height: 42px;
    text-align: center;
}

.input-box {
    margin-top: 44px;
}

.input-box div {
    margin-bottom: 22px;
    display: flex;
    background: rgba(244, 244, 244, 1);
    align-items: center;
    border: 1px solid rgba(221, 221, 221, 0.7);
    border-radius: 4px;

}

.text-input {
    height: 42px;
    line-height: 42px;
    width: 300px;
    padding-left: 20px;
    padding-right: 20px;
    background: rgba(244, 244, 244, 1);
}

.text-box {
    display: flex;
    align-items: center;
}

.select {
    display: flex;
    align-items: center;
    margin-right: 33px;
}

.select img {
    height: 14px;
    width: 14px;
    margin-right: 7px;
}

.select p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 16px;
}

h1 {
    font-size: 12px;
    font-weight: 400;
    color: rgba(156, 192, 210, 1);
    line-height: 17px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.login {
    height: 42px;
    width: 340px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 42px;
    background: rgba(41, 127, 171, 1);
    border-radius: 4px;
    margin-bottom: 20px;
}

h2 {
    font-size: 14px;
    font-weight: 400;
    color: rgba(179, 179, 179, 1);
    line-height: 16px;
}
</style>