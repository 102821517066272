<template>
  <div class="footer">
    <div class="code-box">
      <div class="code">
        <div>
          <h6 v-text="$t('footer.help')"></h6>
          <p
            class="click"
            @click.stop="toTextInfoPage('question','常见问题')"
            v-text="$t('footer.question')"
          >常见问题</p>
          <p
            class="click"
            @click.stop="toTextInfoPage('freight_remark','运费说明')"
            v-text="$t('footer.freight')"
          >运费说明</p>
          <p
            class="click"
            @click.stop="toTextInfoPage('pay_remark','支付说明')"
            v-text="$t('footer.pay')"
          >支付说明</p>
          <p
            class="click"
            @click.stop="toTextInfoPage('privacy_policy','隐私政策')"
            v-text="$t('footer.policy')"
          >隐私政策</p>
          <p
            class="click"
            @click.stop="toTextInfoPage('user_agreement','用户协议')"
            v-text="$t('footer.user')"
          >用户协议</p>
        </div>
        <div>
          <h6 v-text="$t('footer.contact')"></h6>
          <div v-if="contactMobile">
            <img src="../assets/icon/telephone.png" />
            <p v-text="contactMobile">023-68529293</p>
          </div>
          <div v-if="contactEmail">
            <img src="../assets/icon/mailbox.png" />
            <p v-text="contactEmail">sqwq@163.com</p>
          </div>
          <!-- <div class="click" @click.stop="toFeedbackPage">
            <img src="../assets/icon/opinion.png" />
            <p v-text="$t('footer.opinion')">意见反馈</p>
          </div>-->
        </div>
        <div v-show="androidImage">
          <h6 v-text="$t('footer.android')"></h6>
          <img :src="androidImage" />
        </div>
        <div v-show="iosImage">
          <h6 v-text="$t('footer.ios')"></h6>
          <img :src="iosImage" />
        </div>
      </div>
      <div class="numb">
        <a
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>{{beian}}</p>
        </a>
        <div></div>
        <p>{{beian_address}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      androidImage: null,
      iosImage: null,
      contactAddress: null,
      contactEmail: null,
      contactMobile: null,
      beian: '',
      beian_address: '',
    }
  },
  created() {
    this.common.httpPost('/api/ajax/contactUsInfo', null, false).then((ret) => {
      if (ret.code == 1) {
        this.androidImage = ret.data.androidImage
        this.iosImage = ret.data.iosImage
        this.contactAddress = ret.data.contactAddress
        this.contactEmail = ret.data.contactEmail
        this.contactMobile = ret.data.contactMobile
      }
    })

    this.fetchRecordCode()
    this.fetchRecordCodeAddress()
  },
  methods: {
    // 获取备案号
    fetchRecordCode() {
      this.common
        .httpPost(
          '/api/ajax/getSystemText',
          {
            key: 'beian',
          },
          false
        )
        .then((ret) => {
          if (ret.code == 1) {
            this.beian = ret.data
          }
        })
    },

    // 获取备案地址
    fetchRecordCodeAddress() {
      this.common
        .httpPost(
          '/api/ajax/getSystemText',
          {
            key: 'beian_address',
          },
          false
        )
        .then((ret) => {
          if (ret.code == 1) {
            this.beian_address = ret.data
          }
        })
    },

    toFeedbackPage() {
      this.$router.push('/feedback')
    },
    toTextInfoPage(key, title) {
      this.$router.push({
        path: '/textinfo',
        query: { key: key, title: title },
        meta: {
          title: title,
        },
      })
    },
  },
}
</script>
<style scoped>
@import '../assets/css/footer.css';
</style>