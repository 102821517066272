import Vue from 'vue'
import {
  Button,
  Link,
  Input,
  Select,
  Option,
  Pagination,
  Image,
  Dialog,
  Table,
  TableColumn,
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../assets/css/index.css'

Vue.use(Button)
Vue.use(Link)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Image)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
