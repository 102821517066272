<template>
  <div class="navigation center-wrap" @mouseleave="isShowCategoryPanel = false">
    <div
      class="navigation-menu click"
      @click.stop="isShowCategoryPanel = !isShowCategoryPanel"
      @mouseover="isShowCategoryPanel=true"
    >
      <img src="../assets/icon/ic-menu.png" />
      <p v-text="$t('nav.category')"></p>
    </div>

    <!-- 导航中的一级分类 -->
    <div class="navigation-cate">
      <p @click.stop="toHomePage" :class="{'active':navCateSelectId==-1}">{{$t('pro.home')}}</p>
      <div v-for="(item,index) in navCateList" :key="item.id">
        <p
          v-if="index<6"
          :class="{'active':navCateSelectId==item.id}"
          @click.stop="toProListPage(item.id)"
        >{{item.name | showNavCategoryText}}</p>
      </div>
      <div v-if="isLogin" style="flex:1">
        <div class="beian" @click="onUserRecordClick">客户备案</div>
      </div>
    </div>

    <!-- 全部分类 -->
    <transition name="el-fade-in-linear">
      <div class="fix-box" v-show="isShowCategoryPanel" @mouseleave="isShowCategoryPanel = false">
        <!-- 一级 -->
        <div class="parant-box">
          <div
            class="parant-item"
            :class="{'selected':category1SelectId==item.id}"
            v-for="item in category1"
            :key="item.id"
            v-show="item.child > 0"
            @mouseover.stop="category1SelectId=item.id"
          >
            <p v-text="item.name"></p>
            <img src="../assets/icon/arrow-right-selected.png" v-if="category1SelectId==item.id" />
            <img src="../assets/icon/arrow-right.png" v-else />
          </div>
        </div>

        <!-- 显示对于选中的的下级分类 -->
        <div class="child-box">
          <!-- 二级 -->
          <div
            class="child-item"
            v-for="item in category2"
            :key="item.id"
            v-show="item.parent_id == category1SelectId && item.child.length"
          >
            <p class="title" v-text="item.name"></p>
            <!-- 三级 -->
            <div class="grandson-box">
              <div
                class="grandson-item"
                @click.stop="toProListPage(child.id)"
                v-for="child in item.child"
                :key="child.id"
                v-text="child.name"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'Navigation',
  data() {
    return {
      navCateList: [],
      navCateSelectId: -1,
      category1: [],
      category1SelectId: null,
      category2: [],
      isShowCategoryPanel: false,
      isLogin: false,
    }
  },
  created() {
    var uuid = localStorage.getItem('Wx1e2PtoP')
    if (uuid) this.isLogin = true

    var self = this
    // 获取一级分类列表
    this.httpGetCategoryList()

    // 当点击页面中空白的部分 如果分类菜单打开了需要将其关闭
    eventBus.$on('pageClick', function () {
      if (self.isShowCategoryPanel) self.isShowCategoryPanel = false
    })
  },
  watch: {
    $route(to, from) {
      this.isShowCategoryPanel = false
    },
  },
  methods: {
    onUserRecordClick() {
      this.$router.push({
        path: '/user/record',
      })
    },

    /**
     * 获取一级分类数据
     */
    httpGetCategoryList() {
      var self = this
      var local = localStorage.getItem('local')
      this.common
        .httpPost(
          '/api/ajax/getCategoryList',
          { local: local ? local : this.common.default_local },
          false
        )
        .then((ret) => {
          if (ret.code == 1) {
            self.navCateList = ret.data.header
            self.category1 = ret.data.category1 // 所有一级分类
            self.category2 = ret.data.category2 // 所有二三级分类
          }
        })
    },
    toHomePage() {
      this.navCateSelectId = -1
      this.isShowCategoryPanel = false
      this.$router.push({
        path: '/',
      })
    },
    /** 商品列表页面 (分类) */
    toProListPage(category_id) {
      this.navCateSelectId = category_id
      this.isShowCategoryPanel = false
      this.$router.push({
        path: '/list',
        query: {
          category_id: category_id,
        },
      })
    },
  },
  filters: {
    showNavCategoryText: function (value) {
      if (value.length <= 6) return value
      else return value.substring(0, 6) + '...'
    },
  },
}
</script>
<style scoped>
@import '../assets/css/navigation.css';
</style>