<template>
    <div class="bindEmail">
        <div class="bigbox">
            <div class="login-box">
                <div class="title-box">
                    <div v-text="$t('bindEmail.title')"></div>
                    <!-- <p class="skip click" @click.stop="fnSkip" v-text="$t('bindEmail.skip')"></p> -->
                </div>
                <div class="input-box">
                    <div>
                        <input type="text" :placeholder="$t('bindEmail.emailEmpty')" class="text-input" maxlength="50" v-model="email">
                    </div>
                    <div class="testing-num">
                        <input type="text" :placeholder="$t('bindEmail.input')" maxlength="4" v-model="email_code">
                        <div class="click sms-code-btn" @click.stop="fnGetSmsCode()" v-text="smsBtnText"></div>
                    </div>
                </div>
                <div class="login click" v-text="$t('bindEmail.reg')" @click.stop="fnBind"></div>
                <img src="../assets/icon/icon-close.png" class="close-icon" @click.stop="fnClose">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BindEmail',
    data() {
        return {
            area: 1, // 大陆地区/香港地区
            userAgreement: false, // 是否用户用户协议
            email: null,
            email_code: null,
            smsBtnText: this.$t('bindEmail.getcode'),
            sendSmsIng: false,
        }
    },
    created() {},
    methods: {
        fnBind() {
            var self = this;
            if (this.checkEmail() != "success") return;
            if (!this.email_code || this.email_code.length != 4) return this.common.showMessage($t('bindEmail.code_wrong'), 'error');
            self.common.httpPost("/api/user/updateUserInfo", {
                email: this.email,
                email_code: this.email_code
            }).then((ret) => {
                self.common.showMessage(ret.msg, ret.code == 1 ? "success" : "error");
                if (ret.code == 1) {
                    self.fnClose();
                }
            })
        },
        /** 获取验证码 */
        fnGetSmsCode() {
            var self = this;
            if (self.sendSmsIng) return false;
            if (this.checkEmail() != "success") return;
            self.httpSendSms(self.email).then((ret) => {
                if (ret.code == 1) self.fnSmsBtnTim();
                self.common.showMessage(ret.msg, ret.code == 1 ? "success" : "error");
            })
        },
        checkEmail() {
            if (!this.email) return this.common.showMessage(this.$t('bindEmail.emailEmpty'), "error");
            var emailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
            if (!emailReg.test(this.email)) return this.common.showMessage(this.$t('bindEmail.emailError'), "error");
            return "success";
        },
        httpSendSms: function(email) {

            return this.common.httpPost("/api/ajax/sendEmailMessage", { email: email })
        },
        // 短信按钮计时
        fnSmsBtnTim: function() {
            // console.log(self.$t('bindEmail.count_down'));
            var self = this;
            var time = 60;
            var timer = setInterval(function() {
                if (time > 0) {
                    self.sendSmsIng = true;
                    self.smsBtnText = time + "s";
                    time--;
                } else {
                    self.smsBtnText = self.$t('bindEmail.getcode');
                    self.sendSmsIng = false;
                    clearInterval(timer);
                }
            }, 1000);
        },
        fnSkip() {
            this.$emit("fnSkip")
        },
        fnClose() {
            this.$emit("close");
        }
    }
}
</script>
<style scoped>
.bigbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 66;
}

.login-box {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 350px;
    width: 340px;
    background: #FFFFFF;
    padding-left: 30px;
    padding-right: 30px;
}

.close-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    top: -46px;
    right: 0px;
}

.title-box {
    border-bottom: 1px solid rgba(204, 204, 204, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-box .skip {
    font-size: 14px;
    color: rgba(153, 153, 153, 1);
}

.title-box div {
    width: 68px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    line-height: 74px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.7);
}

.title-box img {
    height: 49px;
    width: 49px;
}

.testing-num {
    height: 42px;
    width: 340px;
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 0.7);
    display: flex;
    overflow: hidden;
}

.testing-num .sms-code-btn {
    color: rgba(41, 127, 171, 1);
    height: 42px;
    margin: 0;
    display: flex;align-items: center;
    border: none;
    justify-content: center;
}

.testing-num input {
    height: 42px;
    width: 193px;
    padding-left: 20px;
    padding-right: 20px;
    background: rgba(244, 244, 244, 1);
    height: 20px;
    line-height: 20px;
}

.testing-num div {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    color: rgba(204, 204, 204, 1);
    line-height: 42px;
    text-align: center;
}

.input-box {
    margin-top: 44px;
}

.input-box div {
    margin-bottom: 22px;
    height: 42px;
    display: flex;
    background: rgba(244, 244, 244, 1);
    align-items: center;
    border: 1px solid rgba(221, 221, 221, 0.7);
    border-radius: 4px;

}

.text-input {
    height: 20px;
    line-height: 20px;
    width: 300px;
    padding-left: 20px;
    padding-right: 20px;
    background: rgba(244, 244, 244, 1);
}

.text-box {
    display: flex;
    align-items: center;
}

.select {
    display: flex;
    align-items: center;
    margin-right: 33px;
}

.select img {
    height: 14px;
    width: 14px;
    margin-right: 7px;
}

.select p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 16px;
}

h1 {
    font-size: 12px;
    font-weight: 400;
    color: rgba(156, 192, 210, 1);
    line-height: 17px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.login {
    height: 42px;
    width: 340px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 42px;
    background: rgba(41, 127, 171, 1);
    border-radius: 4px;
    margin-bottom: 20px;
}

h2 {
    font-size: 14px;
    font-weight: 400;
    color: rgba(179, 179, 179, 1);
    line-height: 16px;
}
</style>